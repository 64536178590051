/* Color Theme Swatches in Hex */
$blue1: #021526;
$grey1: #405F73;
$grey2: #658DA6;
$blue2: #8BBBD9;
$white1: #F2F2F2;

/* NOELLE BLUE - #00818C */
/* GREY - #6D7587 */
$black: #000000;
$white: #ffffff;
$brown: $blue2;
$green: $blue1;
$qwhite: #e4e4e4;
$darkgrey: #6D7587;
$grey: #cccccc;
// Form Colors
$inputHeight: 60px;
$primaryColor: #6D7587;

/* GENERAL STYLES  ----------------------------------------------------------------------------------------------------------  */
html,
body {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    background-color: #fff;
    box-sizing: border-box;
    margin: 0;
}

.spacer {
    height: 5vh;
}

/* I SET THE GLOBAL FONT SIZE TO 1.2 REM OR 24 PX */

$highlight: $primary-color;

.standard_header {
    position: relative;
    text-align: center;
    font-size: 32px;
    color: #fff;

    &::after {
        content: '';
        position: absolute;
        background-color: #fff;
        width: 120px;
        height: 1px;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
    }
}

.standard_header_brown {
    position: relative;
    text-align: center;
    font-size: 32px;
    color: $blue1;

    &::after {
        content: '';
        position: absolute;
        background-color: $blue1;
        width: 120px;
        height: 1px;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
    }
}

.standard_header_black {
    position: relative;
    text-align: center;
    font-size: 32px;
    color: #000;

    &::after {
        content: '';
        position: absolute;
        background-color: $black;
        width: 120px;
        height: 1px;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
    }
}

.tooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 100%;
    /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
}

.tooltip {
    position: relative;
    display: inline-block;
    background-color: transparent;
    color: $blue1;
    font-size: 56px;
}

.tooltip .tooltiptext {
    visibility: hidden;
    background-color: #fff;
    color: $blue1;
    text-shadow: none;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    width: 500px;
    bottom: 100%;
    left: 50%;
    margin-left: -250px;
    /* Use half of the width (120/2 = 60), to center the tooltip */
}


.cool-link-white {
    display: inline-block;
    text-decoration: none;
    color: #fff;
}

.cool-link-white::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #fff;
    transition: width .3s;
}

.cool-link-white:hover::after {
    width: 100%;
    //transition: width .3s;
}

.cool-link-dark-blue {
    display: inline-block;
    text-decoration: none;
    color: $blue1;
}

.cool-link-dark-blue::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: $blue1;
    transition: width .3s;
}

.cool-link-dark-blue:hover::after {
    width: 100%;
    //transition: width .3s;
}

.cool-link-light-blue {
    display: inline-block;
    text-decoration: none;
    color: $blue2 !important;
}

.cool-link-light-blue::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: $blue2;
    transition: width .3s;
}

.cool-link-light-blue:hover::after {
    width: 100%;
    //transition: width .3s;
}

/* Footer ---------------------------------------------------------------------------------------------- */
/* FOOOTER TESTTTTERRERERER */

.tfooter {
    background-color: $blue1;

    & a {
        color: #fff;
    }

    & .tfooter_logo {
        text-align: center;
        padding-top: 2vh;

        & a > img {
            width: 175px;
        }
    }

    & .tfooter_main {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-basis: 100%;

        & > div {
            padding: 2.5vh 2.5vw;
            width: 33%;
        }

        & .tfooter_social {

            & ul {
                padding-top: 5vh;
                margin-left: 0;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;

                & li {
                    padding: 0 5px;
                    color: transparent;

                    & :hover {
                        filter: opacity(0.5);
                    }

                    & a > img {
                        width: 30px;
                    }
                }
            }
        }

        & .tfooter_contact {

            & .tfooter_contact_row {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                vertical-align: middle;
                justify-content: flex-start;
                padding-top: 5px;
                padding-bottom: 5px;
                width: 100%;
                padding-left: 20%;
                padding-right: 0;

                &:first-of-type {
                    padding-top: 5vh;
                }

                & span {
                    padding-right: 5px;

                    & i {
                        font-size: 32px;
                        color: #fff;
                    }
                }

                & a {
                    padding-left: 5px;
                    font-size: 20px;

                    &:hover {
                        filter: opacity(0.5);
                    }
                }
            }
        }

        & .tfooter_news {

            & div {
                
                padding-top: 5vh;

                &:hover {
                    filter: opacity(0.5);
                }
                
                & a {
                    margin: auto;
                    max-width: 50%;
                    border: 2px solid #fff;
                    color: #fff;
                }
            }

            & div a {
                display: flex;
                flex-direction: row;
                justify-content: center;
                vertical-align: baseline;
                align-items: center;

            }

            & div > a > i {
                font-size: 24px;
                color: #fff;
                padding-right: 10px;
            }

            & div > a > p {
                font-size: 24px;
                color: #fff;
                padding-left: 10px;
            }
        }
    }

    & .tfooter_copy {
        text-align: center;
        padding-left: 5vw;
        padding-right: 5vw;
        padding-top: 5vh;


        & p {
            color: #fff;
            margin-bottom: 0;
            padding-bottom: 2vh;
        }
    }
}

/* End Footer */

/* SVG STYLES  ---------------------------------------------------------------------------------------------------------- */
.rhino_svg {
    height: auto;
    width: 200px;
    padding-bottom: 0;
    padding-top: 0;
    border-bottom: 0;
    border-top: 0;
    margin-top: 0;
    margin-bottom: 0;
}

/* GENERAL BUTTONS ----------------------------------------------------------------------------------------------------------  */

/* END GENERAL BUTTONS  -----------------------------------------------------------------------------------------------------  */
/* FOUNDATION TOP BAR  --------------------------------------------------------------------------------------------------- 
/* NAVIGATION STYLES START HERE */

#snip_head {
    text-align: center;
    position: fixed;
    z-index: 999;
    width: 100vw;
    background-color: #00000080;


}

.scrolled {
    background-color: red;
}

.idiot_loser_dumb_idiot_jacob-toggle {
    display: none;
}

.idiot_loser_dumb_idiot_jacob-toggle-label {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 1em;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.idiot_loser_dumb_idiot_jacob-toggle-label span,
.idiot_loser_dumb_idiot_jacob-toggle-label span::before,
.idiot_loser_dumb_idiot_jacob-toggle-label span::after {
    display: block;
    background: white;
    height: 2px;
    width: 1.6em;
    border-radius: 2px;
    position: relative;
}

.idiot_loser_dumb_idiot_jacob-toggle-label span::before,
.idiot_loser_dumb_idiot_jacob-toggle-label span::after {
    content: '';
    position: absolute;
}

.idiot_loser_dumb_idiot_jacob-toggle-label span::before {
    bottom: 7px;
}

.idiot_loser_dumb_idiot_jacob-toggle-label span::after {
    top: 7px;
}

.idiot_loser_dumb_idiot_jacob {
    position: absolute;
    text-align: left;
    top: 100%;
    left: 0;
    background-color: transparent;
    width: 100%;
    transform: scale(1, 0);
    transform-origin: top;
    transition: transform 400ms ease;
    color: #fff;
}

.idiot_loser_dumb_idiot_jacob ul {
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: #00000090;
}

.idiot_loser_dumb_idiot_jacob li {
    margin-bottom: 1em;
    margin-left: 1em;
    color: #fff;

}

.idiot_loser_dumb_idiot_jacob a {
    color: white;
    text-decoration: none;
    font-size: 18px;
    opacity: 0;
    transition: opacity 150ms ease-in-out;
}

.idiot_loser_dumb_idiot_jacob a:hover {
    color: #fff;
}

.idiot_loser_dumb_idiot_jacob a:visited {
    color: #fff;
}

.idiot_loser_dumb_idiot_jacob-toggle:checked ~ nav {
    transform: scale(1, 1);
}

.idiot_loser_dumb_idiot_jacob-toggle:checked ~ nav a {
    opacity: 1;
    transition: opacity 250ms ease-in-out 250ms;
}

@media screen and (max-width: 850px) {
    #snip_head img {
        width: 150px;
        margin: auto;
        padding-top: 10px;
    }

    .last_li {
        padding-bottom: 20px;
    }

    .idiot_loser_dumb_idiot_jacob-toggle-label {
        padding-left: 10px;
    }


}

@media screen and (min-width: 851px) {


    .idiot_loser_dumb_idiot_jacob-toggle-label {
        display: none;
    }

    #snip_head {
        display: grid;
        grid-template-columns: 1fr auto minmax(600px, 10fr) 1fr;
        background-color: #00000090;
        box-shadow: 0 0 5px #000;
    }

    .logo {
        grid-column: 2 / span 1;
        height: auto;
        width: 200px;
        margin: auto;
    }

    .idiot_loser_dumb_idiot_jacob {
        all: unset;
        grid-column: 3 / 4;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .idiot_loser_dumb_idiot_jacob ul {
        display: flex;
        justify-content: flex-end;
        background-color: transparent;
    }

    .idiot_loser_dumb_idiot_jacob li {
        margin-left: 3em;
        margin-bottom: 0;
    }

    .idiot_loser_dumb_idiot_jacob a {
        opacity: 1;
        position: relative;
    }

    .idiot_loser_dumb_idiot_jacob a::before {
        content: '';
        display: block;
        height: 4px;
        background: #fff;
        position: absolute;
        top: -0.60em;
        left: 0;
        right: 0;
        transform: scale(0, 1);
        transition: transform ease-in-out 250ms;
    }

    .idiot_loser_dumb_idiot_jacob a:hover::before {
        transform: scale(1, 1);
    }
}

/* GENERAL BUTTONS ----------------------------------------------------------------------------------------------------------  */

/* FOUNDATION HERO  ----------------------------------------------------------------------------------------------------------  */

$hero-height: 120vh;

.hero-section {
    background: url('../img/rhino_d.jpg') 50% no-repeat;
    background-size: cover;
    background-position: bottom;
    height: $hero-height;
    text-align: center;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;

    .hero-section-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);


        & h1 {
            color: $blue1;
            font-size: 56px;

            & div {
                padding: 0;
                margin: 0;
            }
        }


        & p {
            color: #fff;
            font-size: 36px;
            text-align: center;
            margin: auto;
            padding-top: 2vh;
        }

        & a {
            margin-left: 1vw;
            margin-right: 1vw;
            margin-top: 2vh;
        }
    }
}


.custom-underline,
.custom-underline:hover,
.custom-underline:focus,
.custom-underline:active {
    text-decoration: none;
    cursor: pointer;
}

.custom-underline::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 90%;
    left: 5%;
    background-color: #fff;
    bottom: 0%;
}

.button_full {
    display: inline-block;
    background-color: $blue1;
    color: #fff;
    font-family: inherit;
    font-size: inherit;
    padding: .75em 1.5em;
    font-weight: 300;
    cursor: pointer;
    transition: 300ms ease;
    border-radius: 100px;


    &:hover {
        background-color: lighten($blue1, 5%);
        color: #fff;
    }

}

.button_clear {
    display: inline-block;
    background-color: transparent;
    color: $blue1;
    border: 2px solid $blue1;
    font-family: inherit;
    font-size: inherit;
    padding: .75em 1.5em;
    font-weight: 300;
    cursor: pointer;
    transition: 300ms ease;
    border-radius: 100px;

    &:hover {
        box-shadow: 0 5px 20px rgba(#000, .5);
        color: #fff;
        background-color: $blue1;
    }
    
}

.button_clear_hero {
    display: inline-block;
    color: #fff;
    border: 2px solid transparent;
    background-color: #00000080;
    font-family: inherit;
    font-size: inherit;
    padding: .75em 1.5em;
    font-weight: 300;
    cursor: pointer;
    transition: 300ms ease;
    border-radius: 100px;

    &:hover {
        box-shadow: 0 5px 20px rgba(#000, .5);
        color: #fff;
        background-color: $blue1;
    }
    
}

/* END FOUNDATION HERO  ----------------------------------------------------------------------------------------------------- */

/* FOUNDATION SPONSORS  --------------------------------------------------------------------------------------------------  */
.sponsors-section {
    height: 60vh;
    background-color: white;

    & h3 {
        text-align: center;
        padding-top: 10vh;
    }

    & p {
        text-align: center;
        padding-top: 20vh;
    }
}

/* END FOUNDATION SPONSORS  ------------------------------------------------------------------------------------------- */

/* FOUNDATION CONTACT POPUP  ----------------------------------------------------------------------------------------------- */


$contact-panel-bg: $blue1;
$contact-panel-height: 500px;
$contact-panel-width: 350px;
$contact-panel-right-offset: 20px;
$contact-panel-button-arrow: ⥣;

.contact-panel-button {
    z-index: 18;
    background: #fff;
    color: $blue1;
    width: auto;
    padding: 0.25rem 1.25rem;
    text-align: center;
    position: absolute;
    top: -40px;
    right: 20px;
    transition: background 0.25s ease-in-out;
    border: 2px solid $blue1;
    border-bottom: 2px solid $blue1;

    &:after {
        content: "\25bc";
        margin-left: 5px;
        color: $blue1;
        z-index: 18;
    }

    &:hover {
        color: #fff;
        background-color: $blue1;
        transition: none;
        border: 2px solid #fff;
    }
}

.contact-panel-button:hover:after {
    color: #fff;
}

.is-active .contact-panel-button:after {
    content: "\25b2";
    color: $blue1;
}

.is-active .contact-panel-button:hover:after {
    color: #fff;
}

.contact-panel {
    padding: 1rem;
    z-index: 18;
    background: #fff;
    width: $contact-panel-width;
    height: $contact-panel-height;
    position: fixed;
    bottom: -$contact-panel-height;
    right: $contact-panel-right-offset;
    transition: bottom 0.5s ease-in-out;
    border-left: 2px solid $blue1;
    border-right: 2px solid $blue1;
    border: 2px solid #6d7587;

    label {
        color: $blue1;
        width: 100%;
    }

    input[type="text"],
    input[type="email"],
    textarea {
        background: #fff;
        transition: background 0.3s ease-in-out;
        border: 2px solid $blue1;
        caret-color: $blue2;
        color: $blue1;

        &:focus {
            background: $white;
        }
    }

    .contact-panel-actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 24px;
        margin-top: 18px;
    }

    .submit-button {
        vertical-align: middle;
        margin: 0 0 1rem 0;
        margin-bottom: 1rem;
        padding: 0.85em 1em;
        border: 1px solid transparent;
        border-radius: 0;
        -webkit-transition: background-color 0.25s ease-out, color 0.25s ease-out;
        transition: background-color 0.25s ease-out, color 0.25s ease-out;
        font-family: inherit;
        font-size: 0.9rem;
        -webkit-appearance: none;
        line-height: 1;
        text-align: center;
        cursor: pointer;
        background: $blue1;

        &:hover {
            background: lighten($blue1, 10%);
        }
    }

    .cancel-button {
        vertical-align: middle;
        margin: 0 0 1rem 0;
        margin-bottom: 1rem;
        padding: 0.85em 1em;
        border: 1px solid transparent;
        border-radius: 0;
        -webkit-transition: background-color 0.25s ease-out, color 0.25s ease-out;
        transition: background-color 0.25s ease-out, color 0.25s ease-out;
        font-family: inherit;
        font-size: 0.9rem;
        -webkit-appearance: none;
        line-height: 1;
        text-align: center;
        cursor: pointer;

        &:hover {
            background: $blue1;
            color: #fff;
        }
    }

    &.is-active {
        bottom: 4px;
    }

    @include breakpoint(small only) {
        width: 100%;
        right: 0;
    }
}

/* END FOUNDATION CONTACT POPUP ---------------------------------------------------------------------------------------------  */

/* SECTION TWO AND THREE  ------------------------------------------------------------------------------------ */

#second_mission {
    background: $green;
    color: #fff;
    padding-left: 10vw;
    padding-right: 10vw;
}

.mission_title {
    padding-bottom: 20px;
}

.mission_subtitle {
    padding-bottom: 15px;
    text-align: center;
    margin-left: 10vw;
    margin-right: 10vw;
}

.mission_paragraph {
    padding-bottom: 25px;
    text-align: center;
    padding-left: 15vw;
    padding-right: 15vw;
}

.sponsors_top_div {
    padding-top: 5vh;
    
    & ul {
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: nowrap;
        
        & li {
            
            & img {
                height: 89px;
                width: 178px;
            }
        }
    }
}

#second_mission,
#third_partners {
    padding-top: 5vh;
    padding-bottom: 5vh;
    padding-left: 5vw;
    padding-right: 5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#third_partners {
    background: #fff;
    color: #333;
    flex-direction: column;
    align-items: center;
}

#third_partners > .menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#third_partners > p {
    max-width: 55vw;
    margin-top: 2rem;
    text-align: center;
    padding-bottom: 5vh;
}

#third_partners > div > img {
    margin: 0 2.5rem;

    &:hover {
        cursor: pointer;
    }
}

.menu > li > img:hover {
    cursor: pointer;
}

.menu > li {
    margin: 20px;
}

#preloaded-contents {
    display: none;
}

.content-provider {
    color: black;
    font-family: 'Montserrat', sans-serif;
}

#rsei_para {
    & a {
        color: $green;
        display: inline-block;
        position: relative;
    }
}

/* END SECTION TWO AND THREE ------------------------------------------------------------------------------------  */

/* SECTION FOUR AND FIVE ------------------------------------------------------------------------------------------  */
#fourth_pic_grid {
    background: #A5BA84;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-gap: 6px;
    /*Review this*/

    & div {
        overflow: hidden;
        cursor: pointer;
        position: relative;
        background-color: #000;
    }

    & div > .div2 {
        top: 0;
        left: 0;
        transform: scale(1.05) !important;
    }

    & div > img {
        transform: scale(1.05);
        transition: transform 0.5s, opacity 0.5s;
        z-index: 16;
        display: block;

        &:hover {
            opacity: 0.3;

        }
    }

    & div > .gallery_text {
        position: absolute;
        z-index: 15;
        margin: 0 auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        /* Adjust this value to move the positioned div up and down */
        background: rgba(0, 0, 0, 0.8);
        font-family: 'Montserrat', sans-serif;
        color: #fff;
        /* Set the width of the positioned div */
    }
}

.div1 {
    grid-area: 1 / 1 / 3 / 2;
}

.div2 {
    grid-area: 1 / 2 / 3 / 4;
}

.div3 {
    grid-area: 1 / 4 / 2 / 5;
}

.div4 {
    grid-area: 2 / 4 / 3 / 5;
}

.div5 {
    grid-area: 3 / 3 / 5 / 5;
}

.div6 {
    grid-area: 3 / 2 / 4 / 3;
}

.div7 {
    grid-area: 3 / 1 / 4 / 2;
}

.div8 {
    grid-area: 4 / 1 / 5 / 2;
}

.div9 {
    grid-area: 4 / 2 / 5 / 3;
}



div > img {
    max-width: 100%;
    height: auto;
}

#fifth_gallery_link {
    display: flex;
    justify-content: center !important;
    background-color: $green;
    color: $white;
    margin: auto;

    & > div > h3 {
        text-align: center;
        position: relative;
        font-size: 2rem;
    }
}


.gallery_button {
    margin: 50px 0;

    & a {
        margin: 0;
    }
}

/* END SECTION FOUR AND FIVE -------------------------------------------------------------------------------------------------- */

/* SECTION SIX AND SEVEN ----------------------------------------------------------------------------------------------------  */

.events_header {
    margin-bottom: 50px;
}

#sixth_events {
    background: $blue1;
    color: #fff;
    padding: 4rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 5vh;
    padding-bottom: 5vh;
    padding-left: 20%;
    padding-right: 20%;
    text-align: center;

    & p {
        font-size: 24px;
        padding-top: 5vh;
    }

    & a {
        color: $blue2;
        display: inline-block;
        position: relative;
    }
}


.seventh_button {
    text-decoration: none;
    color: #000;

    & #seventh_cta {
        padding: 4rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #000;
        height: 15vh;
        padding: 0;
        background-color: $grey2;
        margin: 0;
        transition: all 0.5s ease-out;
        

        &:hover {
            background: darken($grey2, 10%);
            color: #fff;
        }
    }

    & #seventh_cta > h2 {
        padding: 0;
        margin: 0;
        font-size: 1.5rem;
        text-align: center;

        & i {
            padding-left: 10px;
        }
    }
}

/* END SECTION SIX AND SEVEN  ------------------------------------------------------------------------------------------------- */

/* Animals */

.animals {
    padding-top: 5vh;
    padding-bottom: 5vh;
    background-color: $white1;

    & h2 {
        text-align: center;
        padding-top: 5vh;
        color: $white;
    }

    & .row {
        padding-top: 5vh;
    }

}


/* ABOUT PAGE ---------------------------------------------------------------------------------------------------------- */
.about_section_one {
    background-color: $blue2;
    overflow: hidden;
    padding-top: 10vh;
    padding-bottom: 5vh;

    & p {
        text-align: justify !important;
    }

    & .about_about_paragraph {
        padding-top: 5vh;
        color: $blue1;
        padding-left: 10vw;
        padding-right: 10vw;
        display: flex;
        flex-direction: column;
        align-items: center;


        & h3 {
            text-align: center;
            position: relative;


        }

        & p {
            width: 60%;
            text-align: center;
        }
    }
}

.first_paragraph {
    padding-top: 5vh;
    text-align: center !important;
    text-indent: 0 !important;
}

/* TEAM GRID -------------------------------------------------------------------------------- */
.about_section_team {
    background-color: $qwhite;
}

.about_team_header {
    text-align: center;
    position: relative;
    padding-top: 5vh;


}


.about_team_container {
    width: 100%;
    height: auto;
    padding-bottom: 5vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}

.box {
    width: 250px;
    height: 250px;
    position: relative;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 80px;
    outline: 2px solid $green;
    outline-offset: 2px;
    transition: all 0.5s ease-out;


    & img {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &:hover {
        cursor: pointer;
        outline: 2px solid $brown;
        outline-offset: 2px;
    }
}

.con_text {
    position: absolute;
    color: white;
    bottom: 0;
    width: 250px;
    height: 250px;
    padding: 75px 25px;
    opacity: 0;
    text-align: center;
    transition: all 0.5s ease-out;
    overflow: hidden;

    & > h2 {
        font-size: 1.5rem;
    }

    & > p {
        font-size: 1rem;
    }
}

.con_text_name {
    font-size: 1.4rem !important;
}

.box:hover .con_text {
    opacity: 1;
    background-color: $white;
    color: $brown;
}

/* END - TEAM GRID ----------------------------------------------------------------------------------------------------- */

/* ABOUT PROJECTS ----------------------------------------------------------------------------------------------------- */

.about_projects {
    padding-top: 5vh;
    padding-bottom: 5vh;
    background-color: $white1;
}

.about_projects > h3 {
    text-align: center;
    position: relative;

}

$color_white: #fff;
$color_prime: $green;
$color_grey_dark: $brown;

.docs-example-orbit-slide {
    padding: 2rem 4rem;
    color: $black;

    .orbit-slide:nth-of-type(1) & {
        background: dodgerblue;
    }

    .orbit-caption {
        color: green;
    }

    .orbit-slide:nth-of-type(2) & {
        background: rebeccapurple;
    }

    .orbit-slide:nth-of-type(3) & {
        background: darkgoldenrod;
    }

    .orbit-slide:nth-of-type(4) & {
        background: lightseagreen;
    }
}

.orbit-next {
    margin-right: 25vw;
}

.orbit-previous {
    margin-left: 25vw;
}

.orbit-bullets {
    display: flex;
    justify-content: center;


}

.blog-card {
    display: flex;
    flex-direction: row;
    margin: 1rem auto;
    box-shadow: 0 3px 7px -1px rgba(#000, .1);
    margin-bottom: 1.6%;
    background: $color_white;
    line-height: 1.4;
    border-radius: 5px;
    overflow: hidden;
    z-index: 0;

    & .description p {
        font-size: 14px;
    }

    a {
        color: inherit;

        &:hover {
            color: $color_prime;
        }
    }

    &:hover {
        .photo {
            transform: scale(1.1);
        }
    }

    .meta {
        position: relative;
        z-index: 0;
        height: 200px;
        cursor: pointer;
    }

    .photo_past_one {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transition: transform .2s;
        background-color: $grey;
        background-image: url("/assets/img/wires.jpg");
    }

    .photo_past_two {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transition: transform .2s;
        background-color: $grey;
        background-image: url("/assets/img/raffle.jpg");
    }

    .photo_past_three {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transition: transform .2s;
        background-color: $grey;
        background-image: url("/assets/img/gillian.jpg");
    }

    .photo_current_one {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transition: transform .2s;
        background-color: $grey;
        background-image: url("/assets/img/shirt.jpg");
    }

    .photo_current_two {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transition: transform .2s;
        background-color: $grey;
        background-image: url("/assets/img/cake.jpg");
    }
    
    .photo_future_one {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transition: transform .2s;
        background-color: $grey;
        background-image: url("/assets/img/seaside.jpg");
    }
    
    .photo_future_two {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transition: transform .2s;
        background-color: $grey;
        background-image: url("/assets/img/puma_v.jpg");
    }

    .photo_project_one {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transition: transform .2s;
        background-color: $grey;
        background-image: url("/assets/img/puma_v.jpg");
    }

    .photo_project_two {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transition: transform .2s;
        background-color: $grey;
        background-image: url("/assets/img/namibia_clothing.jpg");
    }

    .photo_project_three {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transition: transform .2s;
        background-color: $grey;
        background-image: url("/assets/img/namibia.svg");
    }

    .photo_animal_one {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transition: transform .2s;
        background-color: $grey;
        background-image: url("/assets/img/meerkat.jpg");
    }

    .photo_animal_two {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transition: transform .2s;
        background-color: $grey;
        background-image: url("/assets/img/lion.jpg");
    }

    .photo_animal_three {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transition: transform .2s;
        background-color: $grey;
        background-image: url("/assets/img/white_rhino.jpg");
    }

    .photo_animal_four {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transition: transform .2s;
        background-color: $grey;
        background-image: url("/assets/img/vulture.jpg");
    }

    .photo_animal_five {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transition: transform .2s;
        background-color: $grey;
        background-image: url("/assets/img/giraffe.jpg");
    }

    .photo_animal_six {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transition: transform .2s;
        background-color: $grey;
        background-image: url("/assets/img/pang.jpg");
    }

    .photo_animal_seven {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transition: transform .2s;
        background-color: $grey;
        background-image: url("/assets/img/cheetah.jpg");
    }

    .photo_animal_eight {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transition: transform .2s;
        background-color: $grey;
        background-image: url("/assets/img/elephant.jpg");
    }

    .photo_animal_nine {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transition: transform .2s;
        background-color: $grey;
        background-image: url("/assets/img/dikdik.jpg");
    }


    .description {
        padding: 1rem;
        background: $color_white;
        position: relative;
        z-index: 1;
        padding-bottom: 20px;

        h1 {
            line-height: 1;
            margin: 0;
            font-size: 1.3rem;
        }

        h2 {
            font-size: 0.8rem;
            font-weight: 300;
            text-transform: uppercase;
            color: $color_grey_dark;
            margin-top: 5px;
        }

        .read-more {
            text-align: right;
            font-size: 14px;
            position: absolute;
            right: 10px;
            bottom: 10px;
            padding-top: 5vh;

            a {
                color: $color_prime;
                display: inline-block;
                position: relative;
            }
        }
    }

    .paragraph_card {
        margin-bottom: 5vh;
    }

    p {
        position: relative;
        margin: 1rem 0 0;

        &:first-of-type {
            margin-top: 1.25rem;

            &:before {
                content: "";
                position: absolute;
                height: 5px;
                background: $color_prime;
                width: 35px;
                top: -0.75rem;
                border-radius: 3px;
            }
        }
    }

    &:hover {
        .details {
            left: 0%;
        }
    }


    @media (min-width: 640px) {
        flex-direction: row;
        max-width: 700px;

        .meta {
            flex-basis: 40%;
            height: auto;
        }

        .description {
            flex-basis: 60%;

            &:before {
                content: "";
                background: #fff;
                width: 30px;
                position: absolute;
                left: -10px;
                top: 0;
                bottom: 0;
                z-index: -1;
            }
        }

        &.alt {
            flex-direction: row-reverse;

            .description {
                &:before {
                    left: inherit;
                    right: -10px;
                }
            }

            .details {
                padding-left: 25px;
            }
        }
    }
}

#modal_jacob_sehl {
    margin-left: 25%;
    width: 50%;
    margin-right: 25%;
    background-color: #fff;
    color: $green;

    & a {
        color: $grey1;
        display: inline-block;
        position: relative;
    }

    & .close-button {
        margin-top: 10px;
    }
}

#modal_noelle_alcorn {
    margin-left: 25%;
    width: 50%;
    margin-right: 25%;
    background-color: #fff;
    color: $green;

    & .close-button {
        margin-top: 10px;
    }
}

#modal_david_bittner {
    margin-left: 25%;
    width: 50%;
    margin-right: 25%;
    background-color: #fff;
    color: $green;

    & .close-button {
        margin-top: 10px;
    }
}

#modal_ian_britton {
    margin-left: 25%;
    width: 50%;
    margin-right: 25%;
    background-color: #fff;
    color: $green;

    & .close-button {
        margin-top: 10px;
    }
}

#modal_jaimi_tapp {
    margin-left: 25%;
    width: 50%;
    margin-right: 25%;
    background-color: #fff;
    color: $green;

    & .close-button {
        margin-top: 10px;
    }
}



/* ABOUT PROJECTS IMAGE MODAL -------------------------------------------------------------------------------- */
.modal {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 600px;
    height: 600px;
    text-align: center;
    box-sizing: border-box;
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);

    &.visible {
        display: block;
        z-index: 999999999999999999999;
    }
}

.relative_photo {
    position: relative;
}

/* END - ABOUT PROJECTS IMAGE MODAL -------------------------------------------------------------------------------- */

/* END - ABOUT PROJECTS ----------------------------------------------------------------------------------------------------- */

/* END - ABOUT PAGE --------------------------------------------------------------------------------------------------- */

/* SHOP PAGE ---------------------------------------------------------------------------------------------------------- */

.shop_coming_soon {
    height: 60vh;
    background-color: $white1;
    padding-left: 5vw;
    padding-right: 5vw;

    & > h3 {
        padding-top: 30vh;
        display: flex;
        justify-content: center;
        color: $blue1;
        text-align: center;
    }
}

/* END - SHOP PAGE ---------------------------------------------------------------------------------------------------------- */

/* DONATE PAGE ---------------------------------------------------------------------------------------------------------- */

.donate_main {
    background-color: $white1;
    padding-top: 10vh;
    padding-bottom: 5vh;
    text-align: center;
    color: $blue1;

    & a {
        color: $brown;
        display: inline-block;
        position: relative;
    }
}

.donate_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.donate_div1 {
    grid-area: 1 / 1 / 3 / 2;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        background-color: $blue1;
        width: 2px;
        height: 80%;
        left: 50vw;
        bottom: 10vh;
        top: 10vh;

    }
}

.donate_div2 {
    grid-area: 1 / 2 / 2 / 3;
}

.donate_div3 {
    grid-area: 2 / 2 / 3 / 3;
}

.donate_div1,
.donate_div2,
.donate_div3 {
    color: $blue1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;

    & h3 {
        text-align: center;
        position: relative;


    }
}

.donate_div1 {

    padding-left: 5vw;
    padding-right: 5vw;

    & h3 {
        text-align: center;
        position: relative;
        color: $blue1;


    }

    & .donate_top_p {
        padding-top: 50px;
        text-indent: 2rem;
        text-align: justify;
    }

    & p {
        text-indent: 2rem;
        text-align: justify;
    }


}

.donate_div2,
.donate_div3 {

    padding-left: 50px;
    padding-right: 50px;
    color: $blue1;

    & p {
        padding-top: 25px;
        padding-left: 50px;
        padding-right: 50px;

        & a {
            color: $brown;
        }
    }
}

.donate_second {
    padding-top: 5vh;
    padding-bottom: 5vh;
    background-color: #fff;

    & div > h3 {
        text-align: center;
        position: relative;
        color: $blue1;


    }
}

.donate_second_content {
    padding-top: 4vh;
    text-align: center;
    vertical-align: middle;
    color: $blue1;

    & .donate_first_paragraph {
        padding-top: 40px;
    }

    & .donate_second_paragraph {
        padding-bottom: 20px;
    }
}

/* END - DONATE PAGE ---------------------------------------------------------------------------------------------------------- */

/* LEARN PAGE ---------------------------------------------------------------------------------------------------------- */
.learn {
    height: 80vh;
    padding-top: 40vh;
    background-color: $white1;
    text-align: center;
    padding-left: 10vw;
    padding-right: 10vw;

    & h3 {
        color: $blue1;
    }

    & a {
        color: $blue2;
        display: inline-block;
        position: relative;
    }
}

/* END - LEARN PAGE ---------------------------------------------------------------------------------------------------------- */

/* EVENTS PAGE ---------------------------------------------------------------------------------------------------------- */
.events_top > h3 {
    text-align: center;
    position: relative;
}

.events_top {
    background-color: $green;
    color: $white;
    padding-top: 15vh;
    text-align: center;
    padding-left: 20vw;
    padding-right: 20vw;
    padding-bottom: 5vh;

    & p {
        padding-top: 5vh;
        padding-left: 10vw;
        padding-right: 10vw;

        & a {
            color: $brown;
            display: inline-block;
            position: relative;
        }
    }
}


.events_main {
    background-color: #fff;
    padding-top: 5vh;
    padding-bottom: 5vh;
}

.events_main > h3 {
    text-align: center;
    position: relative;
    color: $black;
    padding-top: 5vh;
}

.events_current_h3 {
    padding-top: 5vh !important;
}


/* END - EVENTS PAGE ---------------------------------------------------------------------------------------------------------- */

/* MODAL ---------------------------------------------------------------------------------------------------------- */

.reveal {
    padding: 2em;
    background: transparent;
    border: 0;
    outline: none;
}

.close-button {
    position: absolute;
    top: -0.2em;
    color: $white;
    text-shadow: 2px 2px 8px rgb(0, 0, 0);
    display: block;
    outline: none;

    :hover {
        color: $green;
        text-shadow: 2px 2px 5px rgb(0, 0, 0);
    }
}

/* END - MODAL ---------------------------------------------------------------------------------------------------------- */

/* MODAL MEDIA ---------------------------------------------------------------------------------------------------------- */

.social {
    transition: background 200ms ease-in-out 0s;
    -webkit-transition: background 200ms ease-in-out 0s;
    -moz-transition: background 200ms ease-in-out 0s;
    -o-transition: background 200ms ease-in-out 0s;
    -ms-transition: background 200ms ease-in-out 0s;

    margin-top: 12px;
    -webkit-border-top-left-radius: 1px;
    -moz-border-radius-topleft: 1px;
    border-top-left-radius: 1px;
    -webkit-border-top-right-radius: 1px;
    -moz-border-radius-topright: 1px;
    border-top-right-radius: 1px;
    -webkit-border-bottom-right-radius: 1px;
    -moz-border-radius-bottomright: 1px;
    border-bottom-right-radius: 1px;
    -webkit-border-bottom-left-radius: 1px;
    -moz-border-radius-bottomleft: 1px;
    border-bottom-left-radius: 1px;
    text-indent: 0;
    display: block;
    color: #ffffff;
    height: 50px;
    line-height: 50px;
    width: 100%;
    text-decoration: none;
    text-align: center;

    & span {
        padding-left: 5px;
    }

    &:visited {
        text-decoration: none;
    }
}

.fblogin {
    background-color: #3b5898;
}

.fblogin:hover {
    background-color: #5177c2;
    color: $white;
}

.fblogin:active {
    position: relative;
    top: 1px;
}

.youtube {
    background-color: #bb0000;
}

.youtube:hover {
    background-color: #cb1e1e;
    color: $white;
}

.youtube:active {
    position: relative;
    top: 1px;
    color: $white;
}

.twlogin {
    background-color: #00abee;
}

.twlogin:hover {
    background-color: #4cbde6;
    color: $white;
}

.twlogin:active {
    position: relative;
    top: 1px;
    color: $white;
}

.instagram {
    background-color: #c32aa3;
}

.instagram:hover {
    background-color: #ce4cb3;
    color: $white;
}

.instagram:active {
    position: relative;
    top: 1px;
    color: $white;
}

.instagram {
    background-color: #c32aa3;
}

.instagram:hover {
    background-color: #ce4cb3;
    color: $white;
}

.instagram:active {
    position: relative;
    top: 1px;
    color: $white;
}

.tiktok {
    background-color: #000;
}

.tiktok:hover {
    background-color: #3e3e3e;
    color: $white;
}

.tiktok:active {
    position: relative;
    top: 1px;
    color: $white;
}

/* END - MODAL MEDIA ---------------------------------------------------------------------------------------------------------- */

@media only screen and (min-width: 0px) and (max-width: 640px) {
 
    h3, h2 {
        font-size: 24px !important;
    }
    
    p {
        font-size: 16px !important;
    }
    
    .hero-section-text p {
        font-size: 20px !important;
    }
}




/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 0px) and (max-width: 600px) {

    #seventh_cta > h2 {
        padding: 0;
        margin: 0;
        font-size: 1rem !important;

        & i {
            padding-left: 10px;
        }
    }

    .donate_second_content > p {
        font-size: 12px;
    }
}

@media only screen and (max-width: 768px) {
    $hero-height: 120vh;

    .hero-section {
        background: url('../img/rhino_m.jpg') 50% no-repeat;
        background-size: cover;
        background-position: bottom;
        height: $hero-height;
        text-align: center;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        position: relative;
        max-width: 100vw;
        overflow: hidden;

        .hero-section-text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);


            & h1 {
                color: #fff;
                font-size: 48px;

                & div {
                    padding: 0;
                    margin: 0;
                }
            }


            & p {
                color: #fff;
                font-size: 24px;
                text-align: center;
                margin: auto;
                padding-top: 5vh;
            }

            & a {
                margin-left: 1vw;
                margin-right: 1vw;
                margin-top: 5vh;
            }

        }

        & .hero-section-text > .button_full {
            display: inline-block;
            background-color: #fff;
            color: $blue1;
            font-family: inherit;
            font-size: inherit;
            padding: .75em 1.5em;
            font-weight: 300;
            cursor: pointer;
            transition: 300ms ease;
            border-radius: 100px;


            &:hover {
                background-color: $white1;
            }

        }

        & .hero-section-text > .button_clear {
            display: inline-block;
            background-color: transparent;
            color: #fff;
            border: 2px solid #fff;
            font-family: inherit;
            font-size: inherit;
            padding: .75em 1.5em;
            font-weight: 300;
            cursor: pointer;
            transition: 300ms ease;
            border-radius: 100px;

            &:hover {
                box-shadow: 0 5px 20px rgba(#000, .5);
                color: #fff;
                background-color: $blue1;
            }
        }
    }

    .tooltiptext {
        width: 0px;
    }

    .tooltip {
        color: #fff;
    }


    .tooltip:hover .tooltiptext {
        visibility: visible;
        width: 200px;
        bottom: 100%;
        left: 50%;
        margin-left: -100px;
        /* Use half of the width (120/2 = 60), to center the tooltip */
    }

}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
    #seventh_cta > h2 {
        padding: 0;
        margin: 0;
        font-size: 1rem !important;

        & i {
            padding-left: 10px;
        }
    }

    .donate_second_content > p {
        font-size: 12px;
    }
}




/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 992px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1200px) {}

@media only screen and (max-width: 992px) {
    .about_team_container {
        flex-direction: column;
        justify-content: center;
        align-content: center;
    }

    .about_section_one .about_about_paragraph {
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;

        & p {
            width: 90%;
        }
    }
}



@media only screen and (max-width: 1200px) {

    .slick {
        width: 80vw !important;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }


    .tfooter > .tfooter_main {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        flex-basis: 100%;

        & div {
            width: 100%;
        }

        & .tfooter_contact {
            & .tfooter_contact_row {
                padding-left: 0%;
                justify-content: center;
                padding-left: 5vw;
                padding-right: 5vw;

                & a {
                    text-align: left;
                }

                &:last-of-type {
                    padding-left: 5vw;
                    padding-right: 5vw;
                }
            }
        }
    }

    .tfooter > .tfooter_copy {
        & p {
            padding-bottom: 40px;
            font-size: 16px;
        }
    }

    .about_section_one {
        overflow: hidden;
        padding-top: 10vh;
        padding-bottom: 5vh;

        & p {
            text-align: center !important;
        }
    }

    #sixth_events > article > div {
        max-width: 70%;
    }

    .events_top {
        padding-top: 15vh;
        text-align: center;
        padding-left: 5vw;
        padding-right: 5vw;
        padding-bottom: 5vh;

        & p {
            padding-top: 5vh;
            padding-left: 0px;
            padding-right: 0px;


            & a {
                color: $brown;
                display: inline-block;
                position: relative;
            }
        }
    }

    #modal_jacob_sehl {
        top: 0;
        margin-left: 0;
        width: 100%;
        margin-right: 0;
        background-color: #fff;
        color: $green;

        & a {
            color: $black;
            display: inline-block;
            position: relative;
        }

        & .close-button {
            margin-top: 10px;
        }
    }

    #modal_noelle_alcorn {
        top: 0;
        margin-left: 0;
        width: 100%;
        margin-right: 0;
        background-color: #fff;
        color: $green;

        & .close-button {
            margin-top: 10px;
        }
    }

    #modal_david_bittner {
        top: 0;
        margin-left: 0;
        width: 100%;
        margin-right: 0;
        background-color: #fff;
        color: $green;

        & .close-button {
            margin-top: 10px;
        }
    }

    #modal_ian_britton {
        top: 0;
        margin-left: 0;
        width: 100%;
        margin-right: 0;
        background-color: #fff;
        color: $green;

        & .close-button {
            margin-top: 10px;
        }
    }

    #modal_jaimi_tapp {
        top: 0;
        margin-left: 0;
        width: 100%;
        margin-right: 0;
        background-color: #fff;
        color: $green;

        & .close-button {
            margin-top: 10px;
        }
    }

    .donate_main {

        & .donate_grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 2fr repeat(2, 1fr);
            grid-column-gap: 0px;
            grid-row-gap: 0px;
        }

        .donate_div1 {
            grid-area: 1 / 1 / 2 / 3;
            margin-bottom: 0;
            padding-bottom: 0;

            &::after {
                display: none;
            }
        }

        .donate_div2 {
            grid-area: 2 / 1 / 3 / 3;
            margin-top: 0;
            margin-bottom: 0;
            padding: 0px;

            & p {
                padding: 10px;
                margin-top: 5vh;
            }
        }

        .donate_div3 {
            grid-area: 3 / 1 / 4 / 3;
            margin-top: 0;
            margin-bottom: 0;
            padding: 0px;

            & p {
                padding: 10px;
                margin-top: 5vh;
            }
        }
    }

}



/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    
    #third_partners > p {
    max-width: 55vw;
    margin-top: 2rem;
    text-align: center;
    padding-bottom: 5vh;
    height: 350px;
}
}







































































































































































































.animals > .slick {
    padding-top: 5vh;
}


.slick {
    width: 50vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.slick-prev:before {
    color: $blue1 !important;
    opacity: 1 !important;
}

.slick-next:before {
    color: $blue1 !important;
    opacity: 1 !important;

}

.slick-dots li button:before {
    color: $blue1 !important;
}





/* End */
